
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import formatDate from "xe-utils/toDateString";
export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    var printColumns = [
      { field: "Name" },
      { field: "Mobile" },
      { field: "Email" },
      { field: "Address.FullAddress" },
      { field: "InvoiceNumber" },
      { field: "OrderNumber" },
      { field: "Channel.Name" },
      { field: "CreatedTime" }
    ];

    const gridOptions: GridOptions = {
      stripe: false,
      title: "發票登錄記錄",
      canUpdate: false,
      canDelete: false,
      canCreate: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "發票登錄記錄",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "發票登錄記錄",
        type: "csv",
        types: ["csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      sortConfig: { defaultSort: { field: "CreatedTime", order: "desc" } },
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        {
          field: "Mobile",
          title: "手機",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Address.FullAddress",
          title: "地址",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
          // slots: { default: "column-address" }
        },
        {
          field: "InvoiceNumber",
          title: "發票號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "OrderNumber",
          title: "訂單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "Category.Name",
        //   title: "活動名稱",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        {
          field: "Channel.Name",
          title: "購買通路",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "CreatedTime",
          title: "登錄時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            formatDate(cellValue, "yyyy/MM/dd HH:mm")
        }
      ],
      promises: {
        query: model
          ? params => {
              return model.dispatch("invoiceRecord/query", params);
            }
          : undefined,
        queryAll: model
          ? () => model.dispatch("invoiceRecord/query")
          : undefined,
        save: model
          ? params => model.dispatch("invoiceRecord/save", params)
          : undefined
      },
      modalConfig: { width: "50%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "姓名",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              // clearable: true,
              readonly: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "Mobile",
          title: "手機",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Email",
          title: "Email",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Address.FullAddress",
          title: "地址",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        // {
        //   field: "CategoryId",
        //   title: "活動分類",
        //   span: 24,
        //   slots: { default: "column-category-id" }
        // },
        {
          field: "ChannelId",
          title: "購買通路",
          span: 24,
          slots: { default: "column-channel-id" }
        },
        {
          field: "InvoiceNumber",
          title: "發票號碼",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "OrderNumber",
          title: "訂單號碼",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "CreatedTime",
          title: "登錄時間",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              readonly: true,
              type: "datetime"
            }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }],
        Mobile: [{ type: "string", required: true }],
        InvoiceNumber: [{ type: "string", required: true }],
        OrderNumber: [{ type: "string", required: true }],
        // City: [{ type: "string", required: true }],
        // CategoryId: [{ type: "string", required: true }],
        ChannelId: [{ type: "string", required: true }]
      }
    };

    const categorySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    const channelSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇標籤",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: false,
      showHeader: true,
      addIfNotExists: true,
      promises: {
        find: value => model!.dispatch("channel/find", value), // eslint-disable-line
        query: params => model!.dispatch("channel/query", params), // eslint-disable-line
        insert: row => model!.dispatch("channel/insert", row) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      categorySelectOptions,
      channelSelectOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    close() {
      console.log(this.grid);
    }
  }
});
